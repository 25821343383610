import React from 'react'
import Helmet from 'react-helmet'
import { Link } from 'gatsby'
import Layout from '../components/layout'
import { BLOCKS, MARKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

import pic08 from '../assets/images/pic08.jpg'
import pic09 from '../assets/images/pic09.jpg'
import pic10 from '../assets/images/pic10.jpg'
import { auto } from 'eol'

const Bold = ({ children }) => <span className="bold">{children}</span>
const Text = ({ children }) => <p className="align-left">{children}</p>

const options = {
    renderMark: {
      [MARKS.BOLD]: text => <Bold>{text}</Bold>,
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
    },
  }



const Lesson = ({data}) => {
    const title = data.contentfulLesson.title
    let content = ''
    let htmlContent = ''
    let htmlContentParagraph1 = ''
    let para = ''
    let para1 = ''
    let para2 = ''
    let para3 = ''
    let strongMessage = ''
    let price = ''

    if (data.contentfulLesson.description) {
        htmlContent = data.contentfulLesson.description.json
    }


    if (data.contentfulLesson.paragraph) {
        htmlContentParagraph1 = data.contentfulLesson.paragraph.content.json
    }
 
    para = <section>
        {data.contentfulLesson.photo != null &&
            <Link to="/generic" className="image">
                <img src={data.contentfulLesson.photo.file.url} alt="" />
            </Link>
        }
        <div className="content">
            <div className="inner">
                <p>
                    {documentToReactComponents(htmlContent, options)}
                </p>
            </div>
        </div>
    </section>

    if (data.contentfulLesson.paragraph) {
        para1 = <section>
            {data.contentfulLesson.paragraph.photo != null && 
                <Link to="/generic" className="image">
                    <img src={data.contentfulLesson.paragraph.photo.file.url} alt="" />
                </Link>
            }
            <div className="content">
                <div className="inner">
                    { data.contentfulLesson.paragraph.title != null &&
                        <header className="major">
                            <h3>{data.contentfulLesson.paragraph.title}</h3>
                        </header>
                    }
                    <p>{documentToReactComponents(htmlContentParagraph1, options)}</p>
                    {/* <ul className="actions">
                        <li><Link to="/generic" className="button">Learn more</Link></li>
                    </ul> */}
                </div>
            </div>
        </section>
    }

    if (data.contentfulLesson.paragraph2) {
        para2 = <section>
        {data.contentfulLesson.paragraph2.photo != null && 
            <Link to="/generic" className="image">
                <img src={data.contentfulLesson.paragraph2.photo.file.url} alt="" />
            </Link>
        }
        { data.contentfulLesson.paragraph2.content != null && 
            <div className="content">
                <div className="inner">
                    { data.contentfulLesson.paragraph2.title != null &&
                        <header className="major">
                            <h3>{data.contentfulLesson.paragraph2.title}</h3>
                        </header>
                    }
                    <p>{documentToReactComponents(data.contentfulLesson.paragraph2.content.json, options)}</p>
                    {/* <ul className="actions">
                        <li><Link to="/generic" className="button">Learn more</Link></li>
                    </ul> */}
                </div>
            </div>
        }
        </section>
    }

    if (data.contentfulLesson.paragraph3) {
        para3 = <section>
        {data.contentfulLesson.paragraph3.photo != null && 
            <Link to="/generic" className="image">
                <img src={data.contentfulLesson.paragraph3.photo.file.url} alt="" />
            </Link>
        }
        { data.contentfulLesson.paragraph3.content != null && 
            <div className="content">
                <div className="inner">
                    { data.contentfulLesson.paragraph3.title != null &&
                        <header className="major">
                            <h3>{data.contentfulLesson.paragraph3.title}</h3>
                        </header>
                    }
                    <p>{documentToReactComponents(data.contentfulLesson.paragraph3.content.json, options)}</p>
                    {/* <ul className="actions">
                        <li><Link to="/generic" className="button">Learn more</Link></li>
                    </ul> */}
                </div>
            </div>
        }
        </section>
    }
    
    
    
    return (
        <Layout>
            <Helmet>
                <title>Lesson...</title>
                <meta name="description" content="Generic Page" />
            </Helmet>

            <div id="main" className="alt">
                <section id="one">
                    <div className="inner">
                        
                        <header className="major">
                            <h1>{title}</h1>
                        </header>                        
                    </div>
                </section>
                <section id="two" className="spotlights">
                {para}
                {para1}
                {para2}
                {para3}
                { data.contentfulLesson.strongMessage != null &&
                    <section>
                        <div className="inner" style={{width: '50%', paddingTop: '30px', margin: '0 auto'}}>
                            <h3 style={{textAlign: 'center'}}>
                                {documentToReactComponents(data.contentfulLesson.strongMessage.content.json, options)}
                            </h3>
                        </div>
                    </section>
                }
                { data.contentfulLesson.price != null &&
                    <section>
                        <div className="inner" style={{width: '90%', paddingTop: '13px', margin: '0 auto'}}>
                            <p>
                                {documentToReactComponents(data.contentfulLesson.price.description.json, options)}
                            </p>
                        </div>
                    </section>
                }
            </section>
            </div>

        </Layout>
    )
}

export default Lesson


export const pageQuery = graphql`
  query($slug: String!) {
    contentfulLesson(slug: { eq:  $slug}) {
        id
        title
        price {
            description {
                json
            }
        }
        description {
            json
            content {
                content {
                    value
                }
            }
        }
        paragraph {
            title
            photo {
                file {
                    url
                }
            }
            content {
                json
                content
            }
        }
        paragraph2 {
            title
            photo {
                file {
                    url
                }
            }
            content {
                json
                content
            }
        }
        paragraph3 {
            title
            photo {
                file {
                    url
                }
            }
            content {
                json
                content
            }
        }
        slug
        photo {
            file {
                url
            }
        }
        strongMessage {
            content {
                json
            }
        } 
    }
  }
`;